.route-dashboard .primary-toolbar {
  background-color: var(--ion-color-primary);
}

.route-dashboard ion-toolbar.primary-toolbar .image-button {
  background: url(../../assets/images/logo.svg) no-repeat 50% 50% / auto 100%;
}

.dashboard-header {
  padding: calc(var(--ion-padding)) calc(var(--ion-padding) * 2) 130px calc(var(--ion-padding) * 2);
  background-color: var(--ion-color-primary);
  position: relative;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
}

.dashboard-header * {
  color: var(--ion-color-primary-contrast) !important;
}
.dashboard-header .title {
  font-family: secondary-regular !important;
  line-height: 0.6 !important;
  font-size: 100px;
  margin-bottom: 20px;
}
.dashboard-card:last-of-type {
  margin: 0;
}
.dashboard-card {
  height: 150px;
  border-radius: var(--okx-box-radius);
  background-position: center;
  background-size: cover;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  padding: 5px 20px;
  display: flex;
  align-items: flex-end;
}
.dashboard-card::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.5) 100%);
}
.dashboard-card.loyalty {
  /* background-image: url('../../assets/images/backgrounds/dashboard-loyalty.png'); */
}
.dashboard-card.order {
  /* background-image: url('../../assets/images/backgrounds/dashboard-order.png'); */
}
.dashboard-card.refer {
  /* background-image: url('../../assets/images/backgrounds/dashboard-refer.png'); */
}
.dashboard-cards-wrapper {
  padding: var(--ion-padding) calc(var(--ion-padding) * 2);
  margin-top: -100px;
}
.dashboard-card .subtitle {
  color: var(--ion-color-white) !important;
  font-family: primary-bold !important;
  z-index: 5;
}
.dashboard-card > * {
  z-index: 5;
}
/* .page-dashboard{
  background-color: var(--ion-color-primary);
} */
ion-content.route-dashboard::before {
  position: fixed;
  content: '';
  top: 0;
  background-color: var(--ion-color-primary);
  height: 75%;
  width: 100%;
  z-index: -1;
  border-radius: 0 0 40px 40px;
}
.dashboard-content {
  height: 100%;
  overflow: hidden;
}
.route-dashboard .no-padding {
  height: unset;
  overflow: hidden;
}
.dashboard-promo-bars-wrapper {
  width: 100%;
  z-index: 5;
}
.route-dashboard ion-slides {
  height: 200px;
}
.route-dashboard ion-slide {
  min-height: unset;
}
.route-dashboard ion-slide img {
  width: 100%;
  object-fit: cover;
}
.banner-fullscreen-image-modal .modal-wrapper {
  --height: 100%;
  --width: 100%;
  border-radius: 0 !important;
}
.banner-fullscreen-image-modal .modal-classic-wrapper,
.banner-fullscreen-image-modal .modal-classic-content {
  padding: 0 !important;
}
.banner-fullscreen-image-modal img {
  object-fit: cover;
  height: 100%;
}
.padded-top {
  padding-top: var(--ion-padding);
}
.dashboard-banner {
  width: 100%;
  height: 250px;
  object-fit: cover;
}


ion-content.route-dashboard{
  background-color: var(--ion-color-primary);

}