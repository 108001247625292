.toggle {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.route-register .box-holder {
  margin: 0;
  border: none;
  padding: 0;
}
/* .route-register  .box-holder ion-list{
  border: 1px solid rgba(var(--ion-color-light-rgb), 1);
  padding: 10px;
  border-radius: 5px;
} */
.web #main > ion-content.route-register > .ion-padding {
  height: 85%;
}
.web #main > ion-content.route-register > .ion-padding .absolute-content {
  overflow-y: auto;
}

.route-register .data-picker-input {
  background-color: transparent;
  color: var(--ion-color-primary);
}
.route-register .data-picker-input::placeholder {
  opacity: 0.5;
}
.route-register .date-picker-wrapper ion-label {
  font-size: var(--ion-font-size);
  margin-bottom: 10px !important;
}

.route-register ion-checkbox,
.route-contact-details ion-checkbox,
.route-account ion-checkbox {
  margin-right: 20px;
  --border-radius: 2px;
  --border-color: var(--ion-color-primary);
  --border-width: 1px;
  --checkmark-color: var(--ion-color-primary-contrast);
}
.register-terms-wrapper {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
}
.register-group {
  margin: 25px 0 15px;
}
.route-register .input-field-wrapper {
  margin-bottom: 15px;
}
