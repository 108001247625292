.history-content {
  text-align: center;
  padding: var(--ion-padding);
  height: 100%;
  overflow: auto;
}

.history-item {
  padding: 10px;
  cursor: pointer;
}
.history-item ion-col {
  pointer-events: none;
}
.history-item h2 {
  margin-top: 4px;
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1;
}

.history-item ion-col:first-child {
  /* text-align: left; */
  text-align: justify;
  cursor: pointer;
  align-self: center;
}

.history-item ion-text span.small-text {
  text-transform: initial;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.history-item ion-col p:first-child {
  color: var(--ion-color-gray);
  font-size: 8px;
  margin-bottom: 8px;
  font-weight: bold;
}

.history-item ion-col p:last-child {
  color: var(--ion-color-medium);
  font-size: 9px;
  margin-top: 2px;
  margin-bottom: 10px;
}

.history-item ion-col:last-child p {
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: flex-end;
  text-align: right;
  font-size: var(--ion-font-size);
  margin-top: 25px;
  font-weight: bold;
  color: var(--ion-color-primary);
  margin: 0;
}

.history-item ion-col:last-child p img {
  width: 20px;
  max-width: 100%;
  margin-right: 10px;
}

.history-item ion-col:last-child p.redeem {
  /* text-align: right; */
  font-size: 15px;
  /* margin-top: 25px; */
  /* font-weight: 500; */
  /* color: var(--ion-color-danger); */
  margin: 0;
}

.order-button-wrapper .order-button {
  padding: 3px 8px 2px 8px;
  display: inline-flex;
  justify-content: center;
  font-size: 9px;
  cursor: pointer;
}

.order-button-wrapper .order-button ion-icon {
  font-size: 10px;
}

.order-location-name {
  text-transform: capitalize;
}

.web #main > ion-content.route-history > .no-padding {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
  height: 90%;
  overflow: hidden;
  width: var(--okx-bsmodal-width-lg);
}

.web .route-history .tabs {
  position: relative;
  height: 100%;
  border-radius: inherit;
}
.transaction-points-wrapper .title {
  margin: 0 !important;
  line-height: 1 !important;
}
.transaction-points-wrapper div {
  display: flex;
  align-items: center;
}
.transaction-points-wrapper div ion-icon {
  margin-right: 3px;
}
.no-data-img {
  margin: 30px 0 30px 0;
  width: 120px;
  filter: grayscale(1);
}

.item-status-completed {
  color: var(--ion-color-tertiary);
  border: 1px solid var(--ion-color-tertiary);
}
.item-status-incompleted {
  color: var(--ion-color-danger);
  border: 1px solid var(--ion-color-danger);
}

.history-item.active + .history-expand {
  display: block;
}
.history-item.active {
  border-bottom: none !important;
  padding-bottom: 0;
}
.history-expand {
  display: none;
  padding: 0 10px 10px;
}

.history-expand > div {
  border: 1px solid var(--ion-color-primary);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
}
.history-expand .subtitle{
  text-align: start;
}
