.loyalty-content.scan {
  padding-bottom: 60px;
}

.loyalty-content.scan h2 {
  margin-top: 20px;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 0.044em;
  /* margin-left: 24px; */
}

.loyalty-title-wrap {
  text-align: left;
  padding-left: 30px;
}

.noQrCode {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.qr-holder {
  margin: 0 !important;
}

.qr-holder canvas {
  border: 10px solid #fff;
  border-radius: 5px;
}

/* end second tab */

.points-tab::after {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  content: '';
  /* background-image: linear-gradient(#fff 65%, rgba(255,255,255,0) 180%); */
  background-image: linear-gradient(#fff 62.5%, rgba(255, 255, 255, 0) 130%);
}

.verified-content {
  width: 190px;
  margin: 0 auto;
}

.points-tab .normal-text {
  font-weight: bold;
}

#main > ion-content.route-loyalty > .no-padding .loyalty-title-wrap > ion-text:last-child > span {
  font-weight: bold;
}

#main > ion-content.route-loyalty > .no-padding .absolute-content.points-tab img {
  width: 40%;
}

#main > ion-content.route-loyalty > .no-padding .absolute-content.points-tab .points {
  font-size: 4em;
}

.web #main > ion-content.route-loyalty > .no-padding {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto !important;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
  height: 80%;
  overflow: hidden;
  background-color: var(--ion-color-white);
}

.web .route-loyalty .tabs {
  position: relative;
  height: 100%;
  border-radius: inherit;
}

.web .route-loyalty .tabs ion-slides,
ion-slide {
  height: calc(100vh - 350px);
  min-height: 350px;
}

.web ion-segment-button.segment-button-checked {
  border-bottom-color: var(--ion-color-secondary);
}

.web .points-tab {
  background: transparent none;
}

/* new */
.profile-points {
  font-weight: bold;
  font-size: 20px !important;
}
.loyalty-header {
  padding: 20px;
  color: var(--ion-color-primary-contrast);
  display: flex;
  flex-direction: column;

  background-color: var(--ion-color-primary);
  margin: var(--ion-padding) var(--ion-padding) 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0 0;
  position: relative;
  overflow: hidden;
  border-radius: var(--okx-box-radius);
}

/* .loyalty-header::before {
  content: '';
  position: absolute;
  right: -100px;
  bottom: -100px;
  height: 200px;
  width: 200px;
  border: 35px solid var(--ion-color-secondary);
  z-index: 1;
  border-radius: 50%;
}
 */

.loyalty-header img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
}

.loyalty-header-name {
  z-index: 2;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.user-consumer-id {
  margin: 15px 0;
  font-size: 30px;
}

.loyalty-header .subtitle,
.loyalty-header .small-text,
.loyalty-header .normal-text,
.loyalty-header .title {
  color: var(--ion-color-primary-contrast);
  display: block;
}
.loyalty-history,
.loyalty-content,
.loyalty-redeem-voucher {
  padding: var(--ion-padding);
}
.loyalty-redeem-voucher {
  padding-top: 0;
}
.loyalty-history {
  padding-top: 0;
}
.thin-text {
  opacity: 0.7;
  font-size: var(--okx-small-text-font-size) !important;
}
.transactions-wrapper {
  padding: 0 !important;
  border: none !important;
}
.transactions-wrapper > div {
  padding: 10px;
  border-bottom: 1px solid var(--ion-color-light);
}
.transactions-wrapper > div:last-child {
  border-bottom: 0;
}
.loyalty-rewards-wrapper {
  margin-top: 10px;
  min-height: 70px;
  display: grid;
  align-items: center;
  position: relative;
}

.loyalty-content-wrapper {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.points-balance-value {
  font-size: 24px !important;
  line-height: 1;
}
.loyalty-content-wrapper .loyalty-content-value {
  font-size: 20px;
  margin: 0;
  font-weight: bold;
}
/* .border-color {
  color: var(--ion-color-border) !important;
} */
.loyalty-content-desc {
  margin-top: 10px;
}

.route-loyalty .scrollable-y {
  height: 100%;
}

.web .route-loyalty .scrollable-y {
  overflow-y: auto;
}
.loyalty-reward {
  height: 150px;
  width: 200px;
  background-size: cover;
  border-radius: var(--okx-box-radius);
  position: relative;
  overflow: hidden;
}
.loyalty-rewards-wrapper {
  display: grid;
  gap: 10px;
  grid-auto-flow: column;
  overflow-x: auto;
}
.loyalty-reward-points {
  position: absolute;
  top: 10px;
  right: 0;
  background-color: var(--ion-color-secondary);
  padding: 5px 10px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.loyalty-reward-points .strong-text {
  color: var(--ion-color-white);
}
.loyalty-reward-name {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: var(--ion-color-secondary);
  padding: 10px;
  width: 100%;
  text-align: center;
}
.loyalty-reward-name .strong-text {
  color: var(--ion-color-white);
  text-transform: uppercase;
}

.route-loyalty .no-padding .scrollable-y {
  padding: 0;
}

.loyalty-name {
  text-transform: uppercase !important;
  margin-top: 10px;
}
ion-modal.modal-classic.loyalty-faq-modal .modal-classic-closer ion-icon {
  height: 25px;
  width: 25px;
  right: 10px !important;
  top: 10px;
}
ion-modal.modal-classic.loyalty-faq-modal .modal-classic-wrapper {
  padding: 0;
}
ion-modal.modal-classic.loyalty-faq-modal .modal-classic-wrapper .modal-classic-content {
  padding-left: var(--ion-padding);
  padding-right: var(--ion-padding);
}
.loyalty-faq-modal .modal-wrapper {
  height: 100%;
  width: 100%;
  border-radius: 0 !important;
  text-align: center;
  overflow: unset;
}
.web .loyalty-faq-modal .modal-wrapper {
  max-width: var(--okx-bsmodal-width-md);
  width: 100%;
  height: 85%;
  border-radius: var(--okx-box-radius) !important;
  overflow: hidden;
}
.loyalty-points {
  font-size: 22px !important;
  margin-bottom: 0 !important;
  display: flex !important;
  align-items: center;
}
.loyalty-points ion-icon {
  margin-left: 5px;
  cursor: pointer;
}
.loyalty-text-12 {
  font-size: 12px !important;
}

.loyalty-text-10 {
  font-size: 10px !important;
}
.transaction-points-wrapper {
  background-color: var(--ion-color-white);
  height: 50px;
  width: 50px;
  border-radius: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}
.transaction-points-wrapper .subtitle {
  line-height: 1;
}
.route-loyalty .page-title {
  margin-left: var(--ion-padding);
}
.web .route-loyalty .page-title {
  margin-top: var(--ion-padding);
}
.loyalty-vouchers-wrapper {
  margin: var(--ion-padding);
}
.loyalty-vouchers-wrapper .redeem-voucher-field-wrapper {
  margin-bottom: var(--ion-padding);
}

.loyalty-vouchers-wrapper .subtitle {
  margin-bottom: 10px;
}
.loyalty-vouchers-wrapper .input-field-container .subtitle {
  margin-bottom: 0;
}
ion-content.route-loyalty {
  overflow: hidden;
}
.route-loyalty .input-field-container .normal-text {
  font-size: var(--okx-subtitle-font-size);
  font-weight: bold !important;
}
.route-loyalty .input-field-container,
.route-loyalty .redeem-gift-voucher-component-wrapper ion-button {
  margin-bottom: 0;
}
.loyalty-header-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.loyalty-header-content > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.15);
  padding: 10px 5px;
  border-radius: var(--okx-box-radius);
  justify-content: space-between;
}
.loyalty-header-content > div * {
  line-height: 1.1;
}
.loyalty-header-content .title {
  font-size: 16px;
  margin-bottom: 3px;
  color: #ede1d0;
}
.loyalty-header-content .subtitle {
  font-size: 16px;
  color: var(--ion-color-secondary) !important;
}

.loyalty-validation-wrapper {
  margin: 0 var(--ion-padding) var(--ion-padding);
  padding: 25px 0 10px;
  margin-top: -15px;
  text-align: center;
  background-color: var(--ion-color-white);
  border-radius: 0 0 var(--okx-box-radius) var(--okx-box-radius);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.loyalty-validation-wrapper .normal-text {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px !important;
}
.loyalty-validation-wrapper ion-icon {
  height: 20px;
  width: 20px;
  margin-left: 10px;
}
.voucher-wrapper {
  background-color: rgba(144, 144, 144, 0.2);
  border-radius: var(--okx-box-radius);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.voucher-preview {
  text-align: start;
  height: 120px;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  font-size: 18px;
  font-weight: bold;
  padding: 20px;
  border-radius: var(--okx-box-radius);
  position: relative;
  background-image: url(../../assets/images/voucher-overlay.png);
  background-repeat: no-repeat;
}
.voucher-label {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.voucher-preview::before {
  content: '';
  background-image: url(../../assets/images/logo.svg);
  height: 50px;
  width: 60px;
  bottom: 20px;
  right: 20px;
  position: absolute;
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
}
.loyalty-header-information-button {
  height: 25px;
  width: 25px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
  cursor: pointer;
}
.wallet-button {
  margin: 0 auto var(--ion-padding);
  display: flex;
  --background: transparent;
}
.web .wallet-button {
  margin: 10px 0 0 0;
}
.wallet-button img {
  position: relative;
  width: 200px;
}
.web .wallet-button img {
  width: 150px;
}
@media screen and (min-width: 350px) {
  .loyalty-header-content .title {
    font-size: 18px;
  }
  .loyalty-header-content .subtitle {
    font-size: 18px;
  }
}
