.history-order-bar,
.history-order-bar-scrim {
  z-index: 5;
}

.history-order-bar {
  width: 100%;
  background-color: var(--ion-color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  text-transform: uppercase;
  color: var(--ion-color-primary-contrast) !important;
}

.history-order-bar * {
  color: var(--ion-color-primary-contrast) !important;
}

/* .history-order-bar-label {
  color: var(--ion-color-secondary-contrast);
} */
