.promo-bar,
.promo-bar-scrim {
  z-index: 5;
}

/* .promo-bar-scrim {
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.000000001);
} */

.promo-bar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px var(--ion-padding);
  width: 100%;
  z-index: 5;
  background-color: var(--ion-color-primary);
}
.promo-bar {
  left: 0;
  right: 0;
  height: 40px;
  display: flex;
  align-items: center;
  background: var(--ion-color-white);
  width: 100%;
  display: flex;
  /* justify-content: center; */
  border-radius: 20px;
  padding-left: var(--ion-padding);
}
.promo-bar::before {
  content: '';
  height: 25px;
  width: 25px;
  background: var(--ion-color-white) url(../../assets/images/info-white.svg) no-repeat;
  margin-right: 5px;
}
.promo-bar.warning::before {
  background-image: url(../../assets/images/warning-white.svg);
}

.promo-bar.error::before {
  background-image: url(../../assets/images/error-white.svg);
}

.promo-bar-type {
  text-transform: uppercase;
  color: var(--ion-color-primary);
}
.promo-bar-body {
  color: var(--ion-color-primary);
  font-weight: bold;
}
