ion-content div.refer-a-friend {
  /* background-image: url(../../assets/images/backgrounds/main-bg.jpg); */
  background-size: contain;
  background-repeat: no-repeat;
}

ion-content div.refer-a-friend::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 240px;
  z-index: 1;
  background-image: linear-gradient(rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 90%);
}

.raf-card {
  border-radius: var(--ion-card-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  margin: 0;
}

.raf-card ion-card-content {
  padding: 30px;
  font-size: var(--ion-font-size);
}
.route-refer-a-friend .raf-card {
  height: 100%;
}
.route-refer-a-friend .raf-card ion-card-content {
  height: 100%;
  overflow-y: auto;
}
.raf-card ion-card-content .raf-info-title {
  padding: 0 2px;
  font-weight: bold;
}

#main > ion-content.route-refer-a-friend > .ion-padding ion-item {
  --border-color: var(--ion-color-light);
}

.web .static.route-refer-a-friend {
  background: transparent none;
}

.web #main > ion-content.route-refer-a-friend > .ion-padding .absolute-content {
  border-radius: inherit;
  overflow: hidden;
}

.web .route-refer-a-friend .raf-card {
  border-radius: inherit;
  box-shadow: none;
  max-height: 100% !important;
}

.refer-fiend-email {
  opacity: 0.8;
}
