.route-apply-loyalty .swiper-slide {
  text-align: left;
}

.route-apply-loyalty .incrementer-quantity-solo {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.route-apply-loyalty .incrementer-quantity-value {
  font-size: 46px;
  color: var(--ion-color-primary) !important;
}
.route-apply-loyalty .incrementer-decrease,
.route-apply-loyalty .incrementer-incease {
  border: 1px solid var(--ion-color-secondary);
  border-radius: 4px;
}
.route-apply-loyalty .incrementer-quantity-unit {
  font-family: primary-bold;
}

.route-apply-loyalty .input-field-container .normal-text {
  color: var(--ion-color-primary) !important;
  font-size: var(--okx-subtitle-font-size);
  font-weight: bold;
}
