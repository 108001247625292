.validate-modal.modal-wrapper{
  height: 100%;
  width: 100%;
  border-radius: 0;
  text-align: center;
  overflow: unset;
  border-radius: 0 !important;
}
.web .validate-modal.modal-wrapper{
  border-radius: var(--okx-box-radius) !important;
  overflow: hidden;
}
.validate-modal.modal-wrapper> .ion-page {
  min-width: 100%;
  padding: 40px var(--ion-padding) var(--ion-padding);
  overflow: auto;
  background-color: var(--okx-background-color);
  justify-content: flex-start;
}
.web .validate-modal.modal-wrapper> .ion-page {
  padding: var(--ion-padding);
}
.validate-modal .date-picker-wrapper {
  margin-top: 0 !important;
}
.validate-modal.modal-wrapper> .ion-page h2 {
  font-size: var(--ion-font-size);
  font-family: primary-bold;
  color: var(--ion-color-secondary);
  text-align: center;
  text-transform: capitalize;
  margin-top: 10px;
}

.validate-modal.modal-wrapper> .ion-page .validate-modal-closer ion-icon {
  height: 25px;
  width: 25px;
  right: 10px !important;
  top: 10px;
  z-index: 5;
}
.validate-modal.modal-wrapper> .ion-page .validate-modal-closer {
  position: absolute;
  right: 10px;
  top: 30px;
  z-index: 10;
}
.validate-modal.modal-wrapper> .ion-page ion-icon {
  position: absolute;
  right: 0;
  font-size: 15px;
}
.validate-modal.modal-wrapper> .ion-page ion-icon .validate-modal ion-item {
  --min-height: 20px;
  --inner-padding-end: 0;
}

.modal-content {
  margin-top: 20px;
}

.validate-modal.modal-wrapper.ion-page .inline-input > ion-text .small-text,
.validate-modal.modal-wrapper.ion-page .inline-input > label {
  font-size: var(--ion-font-size);
  color: var(--ion-color-gray);
}
.validate-modal.modal-wrapper.ion-page .inline-input > ion-text,
.validate-modal.modal-wrapper.ion-page .inline-input > label {
  flex: 0 0 auto;
  margin-right: 10px;
}
.validate-modal.modal-wrapper.ion-page .inline-input > ion-input[name='email'] {
  text-overflow: ellipsis;
}
.validate-modal.modal-wrapper.ion-page .inline-input > ion-input input,
.validate-modal.modal-wrapper.ion-page .inline-input.inline-input--validate .data-picker-input {
  padding: 5px 0;
}

.web .validate-modal.modal-wrapper{
  max-width: var(--okx-bsmodal-width-md);
  width: 100%;
  height: 85%;
}

.web .validate-modal.modal-wrapper.validate-modal-closer {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.web .validate-modal.modal-wrapper.validate-modal-closer ion-icon {
  z-index: 5;
}

.web .validate-modal.modal-wrapper.validate-modal-header > br {
  display: none;
}

.web .validate-modal.modal-wrapper.validate-modal-header {
  text-align: left;
}

.web .validate-modal.modal-wrapper.validate-modal-header ion-text .small-text {
  color: var(--ion-color-gray);
}
