:root {
  --clickup-initial-height: 50px;
  /* --clickup-open-height: 50%; */
}

.pill {
  display: inline-block;
  border: 1px solid var(--ion-color-secondary) !important;
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast) !important;
  font-size: var(--okx-small-text-font-size);
  padding: 2px 5px;
  margin: 0 5px 5px 0;
  text-transform: uppercase;
  border-radius: 15px;
}

.pill-action {
  cursor: pointer;
}
