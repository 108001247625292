.route-social .square {
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  position: relative;
  border-radius: 30px;
  padding: 15px;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.route-social .square .subtitle {
  color: var(--ion-color-primary);
}
.soc-icon {
  left: 15px;
  position: absolute;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  border-radius: 5px;
}
.soc-icon ion-icon {
  height: 20px;
  width: 20px;
  fill: var(--ion-color-primary);
}
