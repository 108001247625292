.notification-header {
  border-bottom: 1px solid var(--ion-color-light);
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}
.notification-header-icon {
  height: 40px;
  width: 40px;
  background-color: var(--ion-color-light);
  margin-right: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notification-header-icon svg {
  stroke: var(--ion-color-primary);
  height: 25px;
  width: 25px;
}
.notification-content {
  padding-top: 15px;
}

.notification-content .notification-preview {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.route-notification .absolute-content {
  padding: 0;
}

.route-notification .header-title {
  overflow: hidden;
  width: 60%;
}
.notification-body {
  padding: var(--ion-padding);
}
.notification-cover-image {
  width: 100%;
  max-height: 250px;
  object-fit: cover;
}
.notification-html-content > * {
  margin: 0;
  font-size: var(--ion-font-size) !important;
}
.notification-wrapper {
  margin-bottom: 15px;
}

.notification-status{
  padding: 5px 10px;
  background-color: rgba(var(--ion-color-success-rgb),0.1);
  font-size: var(--ion-font-size);
  color: var(--ion-color-success);
  border-radius: 5px;
}
ion-header.route-notification ion-toolbar ion-buttons:last-of-type {
  display: none;
}
