.gm-holder {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.map-holder {
  position: relative;
  height: 100%;
}

.map {
  width: 100%;
  height: 100%;
}

.gm-style .map-location-holder {
  z-index: 9999999;
}

.map-location-pullup {
  background-color: var(--okx-background-color);
  border-top-left-radius: var(--ion-card-radius);
  border-top-right-radius: var(--ion-card-radius);
}

.map-location-holder {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: var(--okx-background-color);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.map-location-holder {
  border-radius: var(--ion-card-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.map-location-holder ion-content {
  --background: var(--okx-background-color);
}
.map-location-content {
  flex: 1 1 100%;
  display: flex;
  justify-content: stretch;
  align-items: center;
  padding-top: 0;
}
.route-locations .strong-text {
  font-weight: bold;
}
.map-location-content > * {
  flex: 1 1 auto;
}

.map-location-content > .map-location-nav {
  flex: 0 0 0;
}

.map-location-content > .map-location-nav ion-button {
  font-size: 18px;
  --padding-start: 0;
  --padding-end: 0;
}

.map-location-content .swiper-slide {
  display: block;
  text-align: left;
}

.map-location-content ion-content {
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 0;
}

.map-location-content ion-content ion-slide > div {
  padding-bottom: 20px;
  margin-left: 2px;
}

.map-location-content .sectiontitle {
  margin: 0;
  letter-spacing: 0.024em;
}

/* .map-location-content .title {
  margin: 40px 0 0 0 !important;
} */
.map-text {
  display: block;
}
.web .map-location-content .title {
  margin-top: 0px !important;
}
.location-info-segment > div {
  text-align: right;
  display: flex;
  flex-direction: column;
}
.location-info > div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.location-times {
  white-space: pre-line;
}

@supports (-webkit-overflow-scrolling: touch) {
  .map-location-content ion-content {
    position: absolute;
    left: 50px;
    right: 50px;
    top: 0;
    bottom: 0;
    width: auto;
  }
  .map-location-content > .map-location-nav {
    position: absolute;
    width: 50px;
  }
  .map-location-content > .map-location-nav:first-child {
    left: 0;
  }
  .map-location-content > .map-location-nav:last-child {
    right: 0;
  }
}

.gm-style {
  height: 50% !important;
}

.web .gm-style {
  height: 100% !important;
}
.route-locations ion-slide ion-button {
  margin: 0 0 15px 0;
}
/* .route-locations .map-text {
  font-family: primary-bold !important;
} */
