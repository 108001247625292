.payment-card-input {
  border: 0;
  outline: none;
  flex: 1;
  padding-left: 0;
  font-size: var(--ion-font-size);
  background-color: transparent;
  color: var(--ion-color-primary);
}
.payment-card-input::placeholder {
  color: rgba(0, 0, 0, 0.4) !important;
  font-size: var(--ion-font-size);
}
.InputElement {
  font-family: primary-regular !important;
}
.scrollable-y.add-card-scroll {
  overflow: unset;
  overflow-y: unset;
}

.web-stripe-input {
  min-height: 48px;
  display: flex;
  flex: 1;
  align-items: center;
  color: var(--ion-color-primary);
}
.web-stripe-input::placeholder {
  color: var(--ion-color-primary);
}
.web-stripe-input > .__PrivateStripeElement {
  width: 100%;
}

.card-list ion-item {
  flex: 1;
}

/* .web-stripe-input-active {
  border-bottom: 2px solid var(--ion-color-primary);
} */

.card-add-wrapper .data-picker-input {
  border-bottom: 1px solid;
  border-color: var(
    --ion-item-border-color,
    var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
  );
}

.cards .ion-text-wrap > ion-note {
  color: var(--ion-color-primary);
  font-size: 10px;
}

.route-card-add .ion-padding .absolute-content ion-list ion-item .sectiontitle {
  margin: 4px 0;
}

.cards ion-item {
  border: 1px solid var(--ion-color-light);
  border-radius: 10px;
}

.web .scrollable-y.add-card-scroll {
  overflow: hidden;
  overflow-y: auto;
}

.web .route-card-add .ion-padding {
}

.web .route-card-add .ion-padding ion-button:first-child {
  display: none;
}

.web .route-card-add .ion-padding ion-button:last-child {
  margin-bottom: 0;
}
