.order-content-wrapper {
  position: relative;
}

.order-content-wrapper .basket-item-wrapper .basket-item-service-charge-row {
  cursor: pointer;
}
.order-content-wrapper .basket-item-wrapper-0 ion-row:first-child {
  border-top: 0;
}
.order-content-wrapper ion-row {
  border-top: 1px solid var(--ion-color-light);
  padding: 10px 20px 10px 15px;
}

.web .order-summary-sidebar .order-content-wrapper .subtotal-bordered-bottom {
  border-top: 1px solid var(--okx-border-color);
}

.total-bordered-bottom {
  border-bottom: 2px solid var(--okx-border-color);
}

ion-col.grow {
  flex-grow: 3.5;
}

.order-content-wrapper .basket-item-wrapper + ion-row > ion-col > ion-text > span {
  font-weight: normal;
}

.add-items-btn {
  font-size: 11px;
  font-weight: 400 !important;
  text-transform: uppercase;
  margin: 10px 0;
}

.add-items-btn.ion-color-black {
  --ion-color-base: var(--ion-color-secondary);
}

.total-bordered-bottom {
  border-bottom: 1px solid var(--ion-color-black);
}

.total-bordered-top {
  border-top: 1px solid var(--ion-color-black);
}

.subtotal-bordered-bottom {
  border-bottom: 1px solid var(--okx-border-color);
}

ion-col.grow {
  flex-grow: 3.5;
}

ion-modal.modal-classic.remove-apply-voucuher {
  --width: 70%;
  --height: 20%;
  --border-radius: 10px;
}

.web ion-modal.modal-classic.remove-apply-voucuher {
  --width: 500px;
  --height: 15vh;
  --min-height: 110px;
}

.web ion-modal.modal-classic.remove-apply-voucuher .modal-classic-header {
  height: 100%;
  display: grid;
  place-content: center;
  text-transform: uppercase;
}
