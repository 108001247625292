.validate-content {
  margin-top: 50px;
}

ion-item.contact-number {
  --border-color: var(--ion-color-gray);
}

ion-item.contact-number > ion-input {
  --padding-bottom: 6px !important;
  font-size: var(--ion-font-size);
}

.link.link-add-items {
  /* max-width: 110px; */
  /* display: flex;
	justify-content: center;
	align-items: center; */
  background: var(--ion-color-primary);
  padding-left: var(--okx-box-padding-left);
  padding-right: var(--okx-box-padding-right);
  border-radius: 30px;
}

.checkout > .righted > ion-button.link.link-centered {
  background: black;
  color: white;
  border-radius: 20px;
  min-width: 110px;
}

.checkout + .flex-min > ion-button.link {
  text-decoration: underline;
}

.checkout + .flex-min > ion-button.link + ion-button > ion-icon {
  font-size: 15px;
}

.apply-points-modal .modal-wrapper {
  width: 100%;
  height: 100vh;
  border-radius: 0;
  overflow: hidden;
}

.apply-points-modal .incrementer {
  justify-content: space-between;
  padding: 0 10px;
}

.apply-points-modal .incrementer .incrementer-quantity-solo {
  flex: 0 0 auto;
  font-weight: bold;
}

.apply-points-modal .wrap-small-text {
  display: flex;
  justify-content: center;
}

/* .web .static.route-order-summary {
background-image: url(../../assets/images/new-dashboard-01.jpg);
background-repeat: no-repeat;
background-position: 50% 0;
background-size: 100% auto;
}

.web ion-content.route-order-summary {
background-image: url(../../assets/images/new-dashboard-01.jpg);
background-repeat: no-repeat;
background-position: 50% -113px;
background-size: 100% auto;
} */
ion-app:not(.web) {
  background-color: var(--okx-background-color);
}
ion-app:not(.web) .allergen-modal .modal-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 0;
}

ion-app:not(.web) .allergen-modal .modal-wrapper > .ion-page {
  min-width: 100%;
  overflow: auto;
}

ion-app:not(.web) .allergen-modal .modal-wrapper > .ion-page > .modal-classic-wrapper {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

ion-app:not(.web) .allergen-modal .modal-wrapper > .ion-page .sectiontitle {
  font-size: 11px;
  letter-spacing: 0.044em;
}

.web .apply-points-modal .modal-wrapper {
  width: var(--okx-sidebar-width);
}

.web ion-modal.modal-classic.allergen-modal .modal-classic-closer {
  font-size: 20px;
  padding: 11px;
}

.contact-number-label {
  padding-top: 3px;
}

.web .route-order-summary .ion-padding .absolute-content {
  padding: 0;
}

.web .route-order-summary .ion-padding .absolute-content .scrollable-y/*,
.web .route-order-summary .ion-padding .absolute-content .flex-min*/ {
  padding: var(--ion-padding);
}

.web .route-order-summary .checkout {
  margin-bottom: 20px;
}

.web .route-order-summary .ion-padding ion-button:last-child {
  margin-bottom: 0;
}

.web .route-order-summary .ion-padding ion-button.add-items-btn,
.web .route-order-summary .ion-padding ion-button.redeem-points-btn {
  margin: 10px 0 !important;
}

.web .route-order-summary .ion-padding ion-button.redeem-points-btn.disabled,
.web .route-order .no-padding ion-button.redeem-points-btn.disabled {
  --ion-color-dark: gray;
}

.contact-number-icon {
  position: absolute;
  right: 0;
  bottom: 2px;
  font-size: 15px;
  cursor: pointer;
  border-radius: 50%;
  padding: 7px;
  transition: 0.2s ease-in-out;
}

.web .contact-number-icon {
  bottom: 4px;
}

.web .contact-number-icon:hover {
  background: rgba(0, 0, 0, 0.13);
}

ion-button.checkout-btn.greyed {
  /* --background: var(--ion-color-gray-tint); */
  /* --background: var(--ion-color-secondary); */
  color: var(--ion-color-secondary);
}
ion-grid.order-content-wrapper,
ion-grid.order-content-wrapper ion-col {
  padding: 0 !important;
}

.table-number-modal .modal-wrapper {
  height: 250px;
}
.table-number-modal input {
  text-align: center;
}
/* .table-number-modal .modal-wrapper .modal-classic-content{
  background-color: var(--ion-color-primary) !important;
} */
/* .table-number-modal .modal-classic-wrapper{
  background-color: var(--ion-color-primary) !important;
} */

.table-number-modal .modal-wrapper .modal-classic-content .subtitle {
  color: var(--ion-color-primary);
  text-align: center;
}
/* .table-number-modal .modal-wrapper .modal-classic-content input{
  color: var(--ion-color-tertiary);
} */

/* .amount-values, .amount-values *{
  color: var(--ion-color-secondary) !important;
} */
.route-order-summary .amount-values,
.route-order-summary .amount-values *,
.route-order .amount-values,
.route-order .amount-values *,
.route-order-completed .amount-values,
.route-order-completed .amount-values * {
  /* color: var(--ion-color-secondary) !important; */
  border-color: var(--ion-color-light) !important;
  font-weight: bold !important;
}

.order-intro-title {
  display: none;
}
.web .order-intro-title {
  display: block;
}
.order-header-content-wrapper {
  margin: 0 var(--ion-padding);
}
.web .order-header-content-wrapper {
  margin: 0;
}
.order-summary-header-wrapper {
  display: flex;
  background-color: var(--ion-color-white);
  border: 1px solid var(--ion-color-light);
  border-radius: var(--okx-box-radius);
}
.order-summary-header-wrapper > div {
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}
.web .order-summary-header-wrapper > div {
  padding: 5px 15px;
}
.order-summary-header-wrapper > div:nth-of-type(2) {
  align-items: center;
  flex: unset;
  border-left: 1px solid var(--ion-color-light);
}

.order-summary-items-wrapper {
  height: 40px;
  display: flex;
  align-items: center;
}
.add-items-button-wrapper {
  position: absolute;
  top: -40px;
  right: 0;
}
.web .order-summary-wrapper .flex-min {
  margin: 0 var(--ion-padding) var(--ion-padding);
}
.basket-item-wrapper ion-row:not(:first-child) {
  padding-left: var(--ion-padding);
}

.order-summary-additional-button {
  --border-color: transparent !important;
  background-color: var(--ion-color-white);
  --border-radius: 0;
  margin-bottom: 10px;
}
.order-summary-additional-button > ion-text {
  flex: 1;
  text-align: left;
}
.web .summary-wrapper .flex-min {
  margin: var(--ion-padding);
}
