.new-user {
  margin-right: 5px;
  color: var(--ion-color-secondary);
  text-transform: capitalize;
  font-family: primary-bold !important;
}

.web #main > ion-content.route-login > .ion-padding > .absolute-content {
  overflow-y: auto;
}
