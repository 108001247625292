.web #main > ion-content.route-faq > .ion-padding {
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.web .route-faq .ion-padding > div {
  overflow-y: auto;
  max-height: 100%;
  padding-right: 10px;
}
.route-faq p {
  margin: 0 0 15px;
}
